import { StaticImage } from 'gatsby-plugin-image';
import React, { type CSSProperties, type FC } from 'react';

import { OrganizationSchema } from '@/components/head/seo/schemas/organization-schema';

const boxStyle: CSSProperties = {
    borderRadius: '8px',
    overflow: 'hidden',
};

const linkStyle: CSSProperties = {
    padding: '12px 16px',
};

const OtherLoandoServices: FC = () => {
    return (
        <>
            <div style={{ gap: '8px' }} className="d-flex flex-wrap w-100 jc-lg-end">
                <div className="d-grid bg-primary border-primary relative" style={boxStyle}>
                    <StaticImage
                        quality={100}
                        width={118}
                        height={115}
                        src="../../static/assets/footer/pl.png"
                        alt=""
                    />

                    <a
                        href="https://loando.pl"
                        target="_blank"
                        style={linkStyle}
                        className="full-link fs-14"
                        rel="noreferrer noopener"
                    >
                        Loando.pl
                    </a>
                </div>

                <div className="d-grid bg-primary border-primary relative" style={boxStyle}>
                    <StaticImage
                        quality={100}
                        width={118}
                        height={115}
                        src="../../static/assets/footer/cz.png"
                        alt=""
                    />

                    <a
                        href="https://loando.cz"
                        target="_blank"
                        style={linkStyle}
                        className="full-link fs-14"
                        rel="noreferrer noopener"
                    >
                        Loando.cz
                    </a>
                </div>

                <div className="d-grid bg-primary border-primary relative" style={boxStyle}>
                    <StaticImage
                        quality={100}
                        width={118}
                        height={115}
                        src="../../static/assets/footer/mx.png"
                        alt=""
                    />

                    <a
                        href="https://loando.mx"
                        target="_blank"
                        style={linkStyle}
                        className="full-link fs-14"
                        rel="noreferrer noopener"
                    >
                        Loando.mx
                    </a>
                </div>

                <div className="d-grid bg-primary border-primary relative" style={boxStyle}>
                    <StaticImage
                        quality={100}
                        width={118}
                        height={115}
                        src="../../static/assets/footer/ua.png"
                        alt=""
                    />

                    <a
                        href="https://loando.ua"
                        target="_blank"
                        style={linkStyle}
                        className="full-link fs-14"
                        rel="noreferrer noopener"
                    >
                        Loando.ua
                    </a>
                </div>

                <div className="d-grid bg-primary border-primary relative" style={boxStyle}>
                    <StaticImage
                        quality={100}
                        width={118}
                        height={115}
                        src="../../static/assets/footer/es.png"
                        alt=""
                    />

                    <a
                        href="https://loando.es"
                        target="_blank"
                        style={linkStyle}
                        className="full-link fs-14"
                        rel="noreferrer noopener"
                    >
                        Loando.es
                    </a>
                </div>
            </div>

            <OrganizationSchema />
        </>
    );
};

export default OtherLoandoServices;
