import React, { type FC } from 'react';

import { site } from '@/utils/site';

export const OrganizationSchema: FC = () => {
    const schema = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        legalName: site.name,
        url: site.url,
        logo: `${site.url}/assets/logo.svg`,
        sameAs: site.hreflang.map((root) => root.url),
    });

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />;
};
